<template>
  <div class="tag" :class="`bg-${bgcolor}`">
    <SvgIcon class="tag-icon" :name="icon" size="xs" v-if="icon" />
    <span class="tag-text">{{ text }}</span>
    <slot>
      <Btn
        v-if="button"
        class="tag-btn"
        size="xs"
        round
        :icon="buttonIcon"
        iconSize="xs"
      />
    </slot>
  </div>
</template>

<script>
import SvgIcon from '@/components/base/SvgIcon.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'Tag',

  components: {
    SvgIcon,
    Btn,
  },

  props: {
    text: {
      type: String,
      required: true,
    },

    id: {
      type: [Number, String],
      default: null,
    },

    color: {
      type: String,
      default: null,
    },

    icon: {
      type: String,
    },

    button: {
      type: Boolean,
    },

    buttonIcon: {
      type: String,
      default: 'times-circle',
    },
  },
  data() {
    return {
      bgcolor: 'material-color-18',
    }
  },
  created() {
    if (this.color) {
      this.bgcolor = this.color
    }
    if (this.id) {
      if (typeof this.id === 'string') {
        this.bgcolor = `material-color-${this.id.length % 19}`
      } else {
        this.bgcolor = `material-color-${this.id % 19}`
      }
    }
  },
}
</script>

<style lang="scss" scoped>
/* TAG */

$tag-horizontal-padding: 1.2rem;
$tag-horizontal-padding-icon: 0.8rem;
$tag-vertical-padding: 0.8rem;
$tag-min-width: 8rem;

.tag {
  display: inline-flex;
  align-items: center;
  min-width: $tag-min-width;
  border-radius: 1000rem;
  color: white;
}

.tag-text {
  flex-grow: 1;
  padding: $tag-vertical-padding $tag-horizontal-padding;
  text-align: center;
  font-weight: $font-weight-semibold;
  line-height: 1;
  white-space: nowrap;
}

.tag-icon {
  display: block;
  margin-left: $tag-horizontal-padding-icon;
}

.tag-btn {
  color: black;
  transition: all 0.25s;

  @include hocus() {
    color: white !important;
  }
}
</style>
